<script lang="ts">
	import { classes } from '@thearc-hq/ui-kit/utils';

	export let errors;
	export let name: string;
	export let checked: boolean;
	export let disabled: boolean = false;
	export let required: boolean = false;
	let className = '';

	$: error = errors.find((s) => s.includes(name));

	export { className as class };
</script>

<div class={classes('space-y-2', className)}>
	<div class="flex items-start space-x-2 select-none">
		<input
			id={name}
			type="checkbox"
			{disabled}
			{required}
			class={classes(
				'w-5 h-5 rounded focus:shadow-none cursor-pointer text-accent-primary hover:border-dark-secondary disabled:hover:border-dark-background disabled:text-dark-background disabled:border-dark-background focus:outline-accent-primary_01 focus:shadow-none',
				error
					? 'text-special-danger border-special-danger focus:outline-special-danger focus:border-special-danger'
					: ''
			)}
			aria-invalid={!!error}
			aria-describedby={`${name}-error`}
			bind:checked
		/>

		<label for={name} class="cursor-pointer text-p-14 text-dark-primary">
			<slot />
		</label>
	</div>

	{#if error}
		<p class="text-special-danger text-p-12" id={`${name}-error`}>
			{error}
		</p>
	{/if}
</div>
